import styled from "styled-components";

export const StoryContainer = styled.div`
  margin: 0 auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 32px;
  width: 100%;
  line-height: 1.5;
  margin-bottom: 0;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const Paragraph = styled.p`
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: -0.3px;
  margin-top: 4px;

  @media (max-width: 400px) {
  }
`;

export const List = styled.ul`
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: -0.3px;
  margin-top: 4px;

  @media (max-width: 400px) {
  }
`;

export const Highlight = styled.span`
  background-color: rgba(252, 51, 89, 0.16);
  color: #580112;
`;

export const ImageContainer = styled.div`
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 400px) {
    width: 100vw;
  }
`;

export const Image = styled.img`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
