import React from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";
import Resume from "../images/Michael-Chun-Resume.pdf";

const Navbar = props => {
  return (
    <div>
      <Nav>
        <StyledLink to="/" activeClassName={"activeLink"}>
          Projects
        </StyledLink>
        <StyledExternal
          href={Resume}
          target="_blank"
          activeClassName={"activeLink"}
        >
          Resume
        </StyledExternal>
        <StyledExternal
          href="mailto:michael.chun03@gmail.com"
          target="_blank"
          activeClassName={"activeLink"}
        >
          Contact
        </StyledExternal>
        <StyledLink to="/about" activeClassName={"activeLink"}>
          About
        </StyledLink>
      </Nav>
    </div>
  );
};

export default Navbar;

const Nav = styled.div`
  margin: 32px 0 32px auto;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 400px) {
    justify-content: center;
    margin: 8px 0;
  }
`;

const sharedStyle = css`
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  padding: 10px 16px;
  transition: 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  &:hover {
    background: white;
    color: #333333;
  }

  @media (max-width: 400px) {
    margin-right: 0px;
  }
`;

const StyledLink = styled(Link)`
  ${sharedStyle}
`;

const StyledExternal = styled.a`
  ${sharedStyle}
`;
