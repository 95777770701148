import React from "react";
import styled from "styled-components";
import { FaHeart } from "react-icons/fa";
import SocialIcons from "./SocialIcons";

const Footer = props => (
  <Container>
    <div>
      <SocialIcons />
      <Paragraph>
        Made with <FaHeart size="12px" color="rgb(220, 20, 60)" /> in Boston
      </Paragraph>
    </div>
  </Container>
);

export default Footer;

const Container = styled.div`
  border-top: 1px solid rgba(48, 48, 48, 0.1);
  margin-top: 100px;
  padding-top: 12px;
  text-align: center;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`;

const Paragraph = styled.p`
  color: #9ea0a6;
  font-weight: 300;
`;
