import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Navbar from "../components/Navbar";
import {
  StoryContainer,
  DescriptionContainer,
  Title,
  Paragraph,
  Highlight,
  Image,
  ImageContainer,
} from "../styles/StoryStyles";
import StoryIntro from "../components/StoryIntro";
import progress from "../images/not-aim-progress.png";
import splash from "../images/not-aim-splash.png";

const NotAolInstantMessenger = () => (
  <Layout>
    <SEO title="Not AOL Instant Messenger" />
    <Navbar />
    <StoryContainer>
      <StoryIntro
        image={splash}
        deployUrl="https://mgc3.github.io/p4-chat-client/#/"
        repoUrl="https://github.com/MGC3/p4-chat-client"
        skills="Skills: React, Javascript, Socket.IO, Express, MongoDB, Styled Components"
      />
      <DescriptionContainer className="fade-in__second">
        <Title>Intro</Title>
        <Paragraph>
          A chat app inspired by AOL Instant Messenger and Windows XP nostalgia.
          Features multiple chatrooms and realtime messaging, complete with an
          interactive desktop-themed environment.
        </Paragraph>
        <Title>Planning</Title>
        <Paragraph>
          In the beginning, I had a pretty strong vision of what I wanted the
          app to look like and some of the features I wanted to implement
          (basically, getting as close as I could to an AIM clone in 4 days),
          but didn't have a clear idea of how to get there. Before starting
          development, most of my planning phase was creating wireframes with
          pen and paper and trying to flush out as many user flows and edge
          cases as possible ahead of time. I also took some time here thinking
          about how to break these down into components before actually writing
          any code, which I found very helpful.
        </Paragraph>
        <Title>Development</Title>
        <Paragraph>
          After some sketching and brainstorming sessions, I started the
          development phase by doing a deep dive into Socket.IO, as it was the
          biggest unknown for me. The first day and a half started out pretty
          rough since I was still feeling lost on how everything would connect
          in the big picture, but after a few proof of concepts, tons of
          googling, and most importantly, help from peers and instructors,
          things finally started clicking. I gained more confidence during the
          remaining two days and was able to hit my original MVP goal of having
          users chat in one global room, and was able to reach a stretch goal of
          allowing users to create multiple chat rooms.
        </Paragraph>
        <ImageContainer>
          <Image src={progress} alt="Progress towards MVP" />
        </ImageContainer>
        <Paragraph>
          After hitting MVP, I was able to work on some nice-to-haves like sound
          effects and images. It was my first time working with sound files and
          I was pleasantly surprised to learn how straightforward it was to get
          up and running (not to mention, super rewarding to hear the sound
          actually play). I was also able to get icons on the desktop (which
          link to some of my other projects) displaying as well as a clickable
          start menu.
        </Paragraph>
        <Title>If I had more time...</Title>
        <Paragraph>
          Some bigger features I'd like to work on in the future are
          implementing a direct messaging system and allowing users to add
          buddies / send friend requests. Aside from core features, there were
          also A LOT of silly ideas that I still very much want to implement,
          like making users have to wait through the 56k dialup handshake before
          'opening' the app.
        </Paragraph>
      </DescriptionContainer>
    </StoryContainer>
  </Layout>
);

export default NotAolInstantMessenger;
