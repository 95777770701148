import React from "react";
import styled from "styled-components";
import {
  DescriptionContainer,
  Image,
  ImageContainer,
} from "../styles/StoryStyles";

const StoryIntro = ({ image, deployUrl, repoUrl, skills }) => (
  <DescriptionContainer className="fade-in__first">
    <ImageContainer>
      <Image src={image}></Image>
    </ImageContainer>
    <ExternalLinks>
      <a
        href={deployUrl}
        className="underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        <b>
          Go to live website <span className="mobile-arrow">↗</span>
        </b>
      </a>
      <br />
      <a
        href={repoUrl}
        className="underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        <b>
          Go to github repo <span className="mobile-arrow">↗</span>
        </b>
      </a>
    </ExternalLinks>
    <Subtitle>{skills}</Subtitle>
  </DescriptionContainer>
);

export default StoryIntro;

const Subtitle = styled.p`
  margin: 0;
  font-size: 18px;
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 32px;
  @media (max-width: 400px) {
    margin-bottom: 8px;
  }
`;

const ExternalLinks = styled.p`
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: -0.3px;
  width: 100%;
`;
